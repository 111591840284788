import React, { useState } from 'react';
import './destiny-2-quiz.css'

export default function Quiz() {
	const questions = [
		{
			questionText: 'Who was the taken king?',
			answerOptions: [
				{ answerText: 'Savathun', isCorrect: false },
				{ answerText: 'Xivu Arath', isCorrect: false },
				{ answerText: 'Dûl Incaru', isCorrect: false },
                { answerText: 'Oryx', isCorrect: true },
			],
            loreText: 'Oryx, the Taken King, born Aurash and formerly known as Auryx (meaning "Long Thought"), was the sovereign of the Osmium Throne, the God-King of the Hive, and the master of the Taken.'
		},
        {
			questionText: 'What event nearly caused the extinction of the human race?',
			answerOptions: [
				{ answerText: 'The Reckoning', isCorrect: false },
				{ answerText: 'The Collapse', isCorrect: true },
				{ answerText: 'The Breaking', isCorrect: false },
                { answerText: 'The Final Shape', isCorrect: false },
			],
            loreText: 'Once upon a time, humanity had populated all corners of the galaxy — until the Collapse. The evil known as the Darkness forced humans all the way back to Earth and almost destroyed them entirely. The intervention of the Traveler kept humankind alive.'
		},
        {
			questionText: 'When was the Darkness created?',
			answerOptions: [
				{ answerText: 'At the beginning of time', isCorrect: false },
				{ answerText: '10,000 years ago', isCorrect: false },
				{ answerText: 'Before the beginning of time', isCorrect: true },
                { answerText: 'One million years ago', isCorrect: false },
			],
            loreText: 'The Light and the Darkness are primal forces in the world of Destiny. Both have existed since before time and the universe itself began. The Darkness enacts its will upon others, using desiples and/or agents that do its bidding, including the Vex and the Hive. Essentially, it is an eternal force of nature.'
		},
        {
			questionText: 'Who practices a religion known as the Sword Logic?',
			answerOptions: [
				{ answerText: 'The Hive', isCorrect: true },
				{ answerText: 'The Vex', isCorrect: false },
				{ answerText: 'The Cabal', isCorrect: false },
                { answerText: 'The Traveler', isCorrect: false },
			],
            loreText: 'A product of the Darkness, the Hive religion known as Sword Logic is explained in what is essentially their Bible, the Books of Sorrow. Those who practice Sword Logic can do magic by accessing the same sort of power that is found in the Darkness. The books of sorrow are written by Oryx, his sisters, and a handful of others.'
		},
        {
			questionText: 'Where is the Black Garden?',
			answerOptions: [
				{ answerText: 'Venus', isCorrect: false },
				{ answerText: 'The end of the universe', isCorrect: false },
				{ answerText: 'Mars', isCorrect: false },
                { answerText: 'Beyond space and time', isCorrect: true },
			],
            loreText: "The Black Garden may be the place where the Vex come from. Though it can be accessed by a gate on Mars, as well as one on Luna, it is not physically at either of those locations. It is somewhere beyond space and time, linked to the Darkness."
		},
        {
			questionText: 'Who created Ghosts?',
			answerOptions: [
				{ answerText: 'Guardians', isCorrect: false },
				{ answerText: 'The Vex', isCorrect: false },
				{ answerText: 'The Travaler', isCorrect: true },
                { answerText: 'Oryx', isCorrect: false },
			],
            loreText: "After the Collapse, the Traveler created the Ghosts from actual pieces of its own body. The pieces created the mechanical shells that house the inner Light and have an instinctive need to raise their companions from the dead using the Light."
		},
        {
			questionText: 'Who are the Nine?',
			answerOptions: [
                { answerText: 'Dark Matter beings', isCorrect: true },
				{ answerText: 'The original Vex', isCorrect: false },
				{ answerText: 'Emissaries of the Darkness', isCorrect: false },
				{ answerText: 'The rulers of the Fallen', isCorrect: false },
			],
            loreText: "The Nine are beings made from a sort of coalesced Dark Matter — basically, pure thought that came into existence as life in the galaxy emerged. They can only exist as thinking beings as long as other thinking beings exist on the planets of Sol."
		},
        {
			questionText: 'Who was the original wielder of the weapon called Thorn?',
			answerOptions: [
                { answerText: 'Uldren Sov', isCorrect: false },
				{ answerText: 'Dredgen Yor', isCorrect: true },
				{ answerText: 'Lord Felwinter', isCorrect: false },
				{ answerText: 'Saint-14', isCorrect: false },
			],
            loreText: "One of the Weapons of Sorrow, the exotic hand cannon called Thorn, was first used by the Guardian Dredgen Yor. In the beginning, Thorn was known as Rose, and Dredgen Yor went by the name Rezyl Azzir until he fell to Darkness."
		},
        {
			questionText: 'Who founded the Guardians during the Faction Wars?',
			answerOptions: [
                { answerText: 'Rezyl Azzir', isCorrect: true },
				{ answerText: 'Lord Shaxx', isCorrect: false },
				{ answerText: 'Saladin Forge', isCorrect: false },
				{ answerText: 'Andal Brask', isCorrect: false },
			],
            loreText: "It was Rezyl Azzir who founded the Guardians by uniting the Risen during the Faction Wars. Thanks to the influence of the Guardians the war was ended, and those in the city were able to unite against the Fallen. Important to know that a Guardian and a lightbearer may both use the light, but are not the same thing."
		},
        {
			questionText: 'What was the first battle that the Last City fought?',
			answerOptions: [
                { answerText: 'The Battle of the Twilight Gap', isCorrect: false },
				{ answerText: 'The Reef Wars', isCorrect: false },
				{ answerText: 'The Battle of Burning Lake', isCorrect: false },
				{ answerText: 'The Battle of the Six Fronts', isCorrect: true },
			],
            loreText: "Six Fronts was one of the earliest battles fought by the Last City. Four orders of Titans protected the newly constructed walls of the City against invading Fallen forces, and not a single front faltered. Some veterans of the battle believed that the City failed to take the initiative to reclaim lost ground. Many guardians were made famous."
		},
        {
			questionText: 'Do you know who attacked the Last City in the Battle of Burning Lake?',
			answerOptions: [
                { answerText: 'The Taken', isCorrect: false },
				{ answerText: 'The Hive', isCorrect: true },
				{ answerText: 'The Cabal', isCorrect: false },
				{ answerText: 'The Fallen', isCorrect: false },
			],
            loreText: "The Battle of Burning Lake was the City's first major engagement with the Hive in the centuries since the Collapse. The Vanguard's Guardians barely won a victory over the Hive and began studying their weaponry after the battle. Lord Shaxx viewed the Hive as more dangerous than the Consensus realized after Burning Lake and opposed their efforts to continue fighting the Hive by invading the Moon until they had studied them more. This resulted in the great disaster."
		},
        {
			questionText: 'The Golden Age came before the Collapse. What came after?',
			answerOptions: [
                { answerText: 'The End Time', isCorrect: false },
				{ answerText: 'The New Beginning', isCorrect: false },
				{ answerText: 'The Light Age', isCorrect: false },
				{ answerText: 'The Dark Age', isCorrect: true },
			],
            loreText: "The Dark Age is the period that followed the Collapse when the Golden Age ended. According to Lakshmi-2 (destiny's resident racist), 'a thousand kings' rose and fell during this age. It is followed by the City Age. Looking through various sources on the beginning of the Dark Age—which directly succeeded the Golden Age—as well as comments made by Ghost throughout most history, it can be assumed that the Dark Age and those that followed lasted for at least four centuries."
		},
        {
			questionText: 'What dangerous power did the Ahamkara have that led the Great Hunt to destroy them?',
			answerOptions: [
                { answerText: 'Mind reading', isCorrect: false },
				{ answerText: 'Wish Granting', isCorrect: true },
				{ answerText: 'Time Traveling', isCorrect: false },
				{ answerText: 'Portal Creation', isCorrect: false },
			],
            loreText: "The Ahamkara are a mysterious and powerful species of shapeshifting, wish-granting creatures that first appeared in the Sol System after the Traveler arrived. The Ahamkara were said to be wish-fulfilling 'dragons', and were sought after by many Guardians for the paracausal gifts they granted. After much debate it was decided that the bargains made with these creatures came at too high a price, beginning a Great Hunt in which the Ahamkara were made all but extinct."
		},
        {
			questionText: 'With what weapon was Dredgen Yor finally slain?',
			answerOptions: [
                { answerText: 'Thorn', isCorrect: false },
				{ answerText: 'Rose', isCorrect: false },
				{ answerText: 'The Last Word', isCorrect: true },
				{ answerText: 'Apotheosis', isCorrect: false },
			],
            loreText: "Malphur wielded the weapon known as the Last Word to take out Yor. Yor eventually fell to Shin Malphur, in a showdown at Dwindler's Ridge. Shin described Yor as 'the darkest shadow he would ever know.' The legend of Dredgen Yor and Shin Malphur's legendary showdown is known to every Gunslinger Hunter."
		},
        {
			questionText: 'Why did Oryx want revenge during the Taken King expansion?',
			answerOptions: [
                { answerText: 'The Vex destroyed his homeworld.', isCorrect: false },
				{ answerText: 'The Guardians killed his son.', isCorrect: true },
				{ answerText: 'The Fallen killed his sisters.', isCorrect: false },
				{ answerText: 'The Guardians nearly killed him.', isCorrect: false },
			],
            loreText: "Oryx, the taken king and god-king of the Hive, sought revenge against the Guardians for killing his son Crota on Luna. Oryx while attempting to reach earth enganges with a mix of awoken-controlled fallen and awoken forces. While all awoken forces are wiped out, including Mara Sov, this halts Oryxs advance at the outer planets of the system, saving earth."
		},
        {
			questionText: 'Who was the leader of the House of Wolves?',
			answerOptions: [
                { answerText: 'Osiris', isCorrect: false },
				{ answerText: 'Craask', isCorrect: false },
				{ answerText: 'Skolas', isCorrect: true },
				{ answerText: 'Keldar', isCorrect: false },
			],
            loreText: "Skolas, Kell of Kells (formerly known as Skolas, the Rabid and Skolas, Wolf Kell) was a Fallen Captain, who became the Kell of the House of Wolves during their war against the Reef and later sought to unite all of the Fallen Houses under his rule. Skolas is extremely prideful and vicious, even for a Kell. He is also quite delusional, according to Variks. He sees himself as fulfilling the prophecy from the House of Rain, in which he is said that any enemy to face him will 'flee or fall'. It is also said that he will heal the Fallen houses and make them whole again. His failure at the Cybele Uprising drives him to near fanatical levels to succeed where others had failed, securing the future for his house and the Fallen race once and for all."
		},
        {
			questionText: 'SIVA is dangerous nanotechnology created by whom?',
			answerOptions: [
                { answerText: 'The Taken King', isCorrect: false },
				{ answerText: 'Kell of Kells', isCorrect: false },
				{ answerText: 'The Darkness', isCorrect: false },
				{ answerText: 'Clovis Bray', isCorrect: true },
			],
            loreText: "SIVA is a form of self-replicating nanotechnology developed by Clovis Bray during the Golden Age. Originally designed to assist in the construction of extrasolar colonies, it was used by the Warmind Rasputin as a weapon against the Iron Lords, and later by the Splicers of the Fallen House of Devils."
		},
        {
			questionText: 'Whos body was used by Savathun in Season 12 to 15?',
			answerOptions: [
				{ answerText: 'Osiris', isCorrect: true },
				{ answerText: 'Saint-14', isCorrect: false },
				{ answerText: 'Zavala', isCorrect: false },
				{ answerText: 'Crow', isCorrect: false },
			],
            loreText: "Savathun the witch queen obtained the body of Osiris to infiltrate the tower in the last city and learn information about the light. It is hinted that Osiris gave his body up willingly, for what purpose is yet to be seen"
		},
        {
			questionText: 'What is the homeworld of the awoken?',
			answerOptions: [
				{ answerText: 'The Dreaming City', isCorrect: false },
                { answerText: 'Distributary', isCorrect: true },
				{ answerText: 'Yang Liwei', isCorrect: false },
				{ answerText: 'Exodus Green', isCorrect: false },
			],
            loreText: "The Distributary is the home planet and universe of the Awoken race. In the Distributary's universe, time flows differently, and Awoken society has had thousands upon thousands of years to flourish and build."
		},
		{
			questionText: 'Who was kell of the house of devils?',
			answerOptions: [
				{ answerText: 'Chelchis', isCorrect: false },
				{ answerText: 'Craask', isCorrect: false },
                { answerText: 'Solkis', isCorrect: true },
				{ answerText: 'Draksis', isCorrect: false },
			],
            loreText: "The House of Devils are a Fallen House, distinguishable by their red cloaks. They were for the longest time the City's most pervasive foe, surpassing the Hive, until the Red Legion's invasion. Later, former Devils joined their former Baroness to form the House of Salvation, in hopes of destroying the Traveler for abandoning their people."
		},
	];

    const [currentQuestion, setCurrentQuestion] = useState(0)

    const [score, setScore] = useState(false)

    const [answerScore, setAnswerScore] = useState(0)

    const [buttonStyle, setButtonStyle] = useState("quiz-button")

    const [activeButton, setActiveButton] = useState("")

    const [lore, setlore] = useState(false)

    const handleAnswer = async (isCorrect, level) => {
        if(isCorrect===true) {
            setActiveButton(level)
            setlore(true)
            setAnswerScore(answerScore + 1)
        }

        if(isCorrect===false) {
            setButtonStyle("incorrect")
        }
    
    }

    const handleNextButton = () => {
        const nextQuestion = currentQuestion + 1
        setButtonStyle("quiz-button")
        setActiveButton("")
        setlore(false)
        if(nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion)
        } else {
            setScore(true)
        }
    }

	return (
		<div>
            <div className='quiz'>
                {score ? (
                    <div className='score-section'>You scored {answerScore} out of {questions.length}</div>
                ) : (
                    <>
                        <div className='question-section'>
                            <div className='question-count'>
                                <span>Question {currentQuestion + 1}</span>/{questions.length}
                            </div>
                            <div className='question-text'>{questions[currentQuestion].questionText}</div>
                            <div className='next-question-button-container'>
                                <button className='next-question-button' 
                                onClick={handleNextButton}>Next</button>
                            </div>
                        </div>
                        <div className='answer-section'>
                            {questions[currentQuestion].answerOptions.map((answerOptions, level) => 
                            <button className={buttonStyle} 
                                style={{backgroundColor: activeButton === level ? ("green") : ""
                                    }}
                                key={level}
                                onClick={()=> handleAnswer(answerOptions.isCorrect, level)}
                                >{answerOptions.answerText}</button>)}
                        </div>
                    </>
                )}
            </div>
            <div className='lore'>
                <span className='lore-title'>Lore relating to the answer</span>
                <span className='lore-sub-title'>(will only appear when answer correctlty)</span>
                <div className='lore-text'>
                    {lore ? (
                        <div>
                            <br></br>
                            <p>{questions[currentQuestion].loreText}</p>
                        </div>
                    ) : (
                        <div>
                        <br></br>
                        This lore is to die for!
                        </div>
                    )}
                </div>
            </div>
        </div>
	);
}