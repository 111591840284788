import React from "react";
import './index.css'
import Header from '../../../../Components/Header/header';
import { HiArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";


const Friendsgiving = () => {
    return (
        <>
            <head>
                <title>Friendsgiving - 2022</title>
                <meta charset="UTF-8"/>
                <meta name="description" content="Blogs page for Thomas Lee Williams
                personal website"/>
                <meta name="keywords" content="HTML, CSS, JavaScript, React"/>
                <meta name="author" content="Thomas Lee Williams"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </head>
            <div className="main_friendsgiving">
                <Header/>
                <div className="page_friendsgiving">
                    <div className="title_stuff">
                        <Link to="/blogs" className='back_arrow-box'>
                            <HiArrowLeft className="back_arrow"/>
                        </Link>
                        <h1 className="name_day_out">Friendsgiving</h1>
                    </div>
                    <h2 className="date_friendsgiving"><u>25th November 2022</u></h2>
                    <div className="desc_friendsgiving">
                        <p>
                        While thanksgiving is the day that americans celebrate family by cooking a big
                        turkey, friendsgiving is the day you take to celebrate friends by cooking a chicken.
                        </p>
                    </div>
                    <div className="para_friendsgiving">
                        <br></br>
                        <p>
                        So, while I and many others in scotland do not celebrate thanksgiving/friendsgiving, my 
                        american friends em and tyler decided that we should, and it was a brilliant idea. So about
                        almost 2 months in advance, we made sure that we could all be available on the 25th of november
                        and em got to work preparing the day. On the day, I almost missed my train. Got up a little bit 
                        to late after a night of work, had to get ready in almost 10 minutes, get all of my stuff ready 
                        and packed, then sprinted almost an entire mile in 5 minutes to get from my house to the train 
                        station. Took my entire train ride through to edinburgh to recover from that (I'm not in as good
                        a shape as I was a few years ago...haha).  When I got into edinburgh, I decided to prep for my relative large meal with another mean and that was
                        a decent McDonalds Sweet Chili wrap meal with some sub-par fries and a very limited selection of drinks (
                        there was only 1 drink available and that was oasis). To be fair, it was faily decent.
                        </p>
                        <br></br>
                        <br></br>
                        <p>
                        Once that was done
                        I headed round to bobs place for the festivities. When I arrived bob, carina, tyler, ralph, and em were
                        already there, hanging out and already cooking. It wasn't long until everyone else was there and I was
                        being shoo'd outta the kitchen so that tyler, carina, bob, matthew, and em could cook it for the rest
                        of us mortals that do not know how to cook. Took an hour and a half of waiting but finally, we all sat down
                        for a meal and a drink between ourselves.
                        </p>
                        <br></br>
                        <br></br>
                        <div className="grid_friendsgiving">
                            <img src={require('../../../../Assets/friendsgiving-1.jpg')} className='images_in_grid' alt="edinburgh" loading="lazy"/>
                            <img src={require('../../../../Assets/friendsgiving-3.jpg')} className='images_in_grid' alt="edinburgh" loading="lazy"/>
                            <img src={require('../../../../Assets/friendsgiving-5.jpg')} className='images_in_grid' alt="edinburgh" loading="lazy"/>
                            <img src={require('../../../../Assets/friendsgiving-2.jpg')} className='images_in_grid' alt="edinburgh" loading="lazy"/>
                        </div>
                        <br></br>
                        <p>
                        With dinner out of the way, I personally spent some time resting in the room known as the mental ward
                        with a nice blanket that makes you very warm and nice and comfy, as documented below!
                        </p>
                        <br></br>
                        <img src={require('../../../../Assets/friendsgiving-4.jpg')} className='images_in_grid' alt="edinburgh" loading="lazy"/>
                        <br></br>
                        <br></br>
                        <p>
                        Once I had weighted blanket had taken me and I had strayed into thought and time, we decided to play a game of
                        speed UNO. A game that makes anyone sane, insane, and makes anyone who has patiance, screem and
                        punch walls! It is very fun to play and is a recommended good time for anyone wanting to have a "chill"
                        time with friends. With that over and done with, we all said outr goodbyes, I walked home with lizzy, em, and
                        matthew, and it concluded a very nice day!
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Friendsgiving