import React from "react";
import './index.css'
import Header from '../../../../Components/Header/header';
import { HiArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";


const LovejoyConcert = () => {
    return (
        <>
            <head>
                <title>Lovejoy Concert - 2023</title>
                <meta charset="UTF-8"/>
                <meta name="description" content="Blogs page for Thomas Lee Williams
                personal website"/>
                <meta name="keywords" content="HTML, CSS, JavaScript, React"/>
                <meta name="author" content="Thomas Lee Williams"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </head>
            <div className="main_lovejoy">
                <Header/>
                <div className="page_lovejoy">
                    <div className="title_stuff">
                        <Link to="/blogs" className='back_arrow-box'>
                            <HiArrowLeft className="back_arrow"/>
                        </Link>
                        <h1 className="name_day_out">Lovejoy Concert</h1>
                    </div>
                    <h2 className="date_lovejoy"><u>19th September 2023</u></h2>
                    <div className="desc_lovejoy">
                        <p>
                        Many people might now know about Lovejoy, but that doesn't mean that they are not a good band.
                        This day, I ventured south into England to see them perform almost all of my favourite songs.

                        </p>
                    </div>
                    <div className="para_lovejoy">
                        <p>
                        Lovejoy had previously put on tours in the UK, but I had never been able to 
                        make it to one of their concerts. As soon as their tickets when on sale, the 
                        tickets were gone. Thousands of people were trying to get tickets to see them,
                        so I had stiff competition. It got so bad that I spent 4 days devolping a way
                        to auto-reload ticket masters website to get the tickets as soon as they went
                        on sale. I was so determined to see them perform live. It is the only band that
                        I have ever been so determined to see live. You could not believe the feeling of
                        joy when I finally got the tickets. I <b>SCREAMED.</b> One faithful day, in June
                        of 2023, thanks to my little script and some lucky scrolling on twitter, 
                        I secured those tickets <s>and most likely a noise complaint from my 
                        neighbours.</s>
                        <br></br>
                        <br></br>
                        <div className="grid_lovejoy">
                            <div>
                                <img src={require('../../../../Assets/Lovejoy-2.jpg')} className="lovejoy_image"
                                alt="thumbnail-for-post"/>
                                <p className="caption_lovejoy"><i>(Band Before Performance)</i></p>
                            </div>
                            <div>
                                <img src={require('../../../../Assets/Lovejoy-3.jpg')} className="lovejoy_image"
                                alt="thumbnail-for-post"/>
                                <p className="caption_lovejoy"><i>(Wilbur During Performance)</i></p>
                            </div>
                        </div>
                        <br></br>
                        The concert was held in a small venue in Newcastle. I had never been to Newcastle
                        before and it had been a little while since I had travelled anywhere since the 
                        pandemic that wasn't Edinburgh or my home town. I was so excited to be going somewhere
                        new and to see Lovejoy. I had been listening to their music for years and I was so
                        excited to finally see them live. It was held at the O2 City Hall Newcastle.
                        </p>
                        <br></br>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2289.773626024243!2d-1.6098488659685717!3d54.97706891977662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e70c8d88e0f7f%3A0x1843de775fba4647!2sO2%20City%20Hall%20Newcastle!5e0!3m2!1sen!2suk!4v1707452504657!5m2!1sen!2suk" title="Lovejoy Map" width="100%" height="550" loading="lazy"></iframe>
                        <br></br>
                        <br></br>
                        <p>
                        I took the train there, as Newcastle is only a 1 hour 10 minute train ride from my
                        home town and sadly I am unable to drive (Something I am working on). I arrived in
                        Newcastle around 4pm and I had some time to kill before the concert started at 7pm.
                        I decided to go to the venue early and see the line for the band forming. I was
                        expecting a line as it was well documented that Lovejoy had a very dedicated fanbase
                        and that lines started forming well into the morning of the day of the concert. I saw
                        the concert line and looped the building twice. I was honestly stunned and since I was
                        going to get a good spot in the line, I decided to go and get some food. A quick trip
                        to McDonalds to get me filled up and ready for the rest of the night was a requirement
                        since I hadn't eaten since breakfast. I got back to the line and more people had arrived.
                        It was kinda a bummer until the line started moving and I kinda got to slip into the
                        line earlier than I should have. That made me feel both good and bad, but I was what it
                        was.
                        <br></br>
                        <br></br>
                        </p>
                        <div className="grid_lovejoy">
                            <div>
                                <img src={require('../../../../Assets/Lovejoy-4.jpg')} className="lovejoy_image"
                                alt="thumbnail-for-post"/>
                                <p className="caption_lovejoy"><i>(Philza Minecraft On Stage)</i></p>
                            </div>
                            <div>
                                <img src={require('../../../../Assets/Lovejoy-5.jpg')} className="lovejoy_image"
                                alt="thumbnail-for-post"/>
                                <p className="caption_lovejoy"><i>(Wilbur and Ash)</i></p>
                            </div>
                        </div>
                        <br></br>
                        <p>
                        I had seats in the upper left of the arena (if you can call it that). I was far back for sure
                        but I was still able to see the band and the stage. That was my first concern when I got the 
                        tickets but to my surprise, I was able to see the band and the stage fairly well, even that 
                        far back. They played 2 opening bands, a band called "Loupe" and another band called "Good Kid".
                        Both bands were really good and I enjoyed their music. I was really impressed with "Good Kid"'s 
                        cover of "From The Start" by Loufe.
                        </p>
                        <br></br>
                        <div className="grid_lovejoy">
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/2nHOP8g5QQk"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Loupe Band)</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/JzadgFkO9QU"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Good Kid Band's "From The Start")</i></p>
                            </div>
                        </div>
                        <br></br>
                        <p>
                        After the opening bands had finished, Lovejoy came on stage. The crowd went wild. I was also very excited
                        to see them. They opened with "One Day" and the crowd went wild. I was so excited to see them. Again my 
                        expectation was that I wasn't gonna be able to see them and that it wouldn't sound any where near as good
                        as it does from my high quality versions of the songs. I was wrong. They sounded amazing. I was so happy
                        to actually be there and seeing them made me beyond happy. They played most of the songs that I wanted to 
                        hear and I am going to show them all here in the order in which they played. Some songs were not recorded,
                        only due to wanting to enjoy some of them in the moment.
                        </p>
                        <br></br>
                        <br></br>
                        <p className="warning"><b>
                        WARNING THE AUDIO IN THESE VIDEOS ARE NOT ALWAYS THE GREATEST, THIS IS DUE TO THE MICROPHONE
                        ON MY PHONE NOT WORKING PROPERLY. I APOLOGISE FOR THIS AND I HOPE YOU CAN STILL ENJOY THE VIDEOS.
                        </b></p>
                        <br></br>
                        <br></br>
                        <div className="grid_lovejoy_1">
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/zPA6c7FZtj0"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "Concequences")</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/iDDeQZASoKk"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "The Taunt")</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/uLyDWoKtKnw"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "Model Buses")</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/BZmtgaKAXUM"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "Cause For Concern")</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/NMJe8w4Tsy4"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "Scum")</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/2ntT3pukZoA"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "Warsaw")</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/CAQVmxuSCck"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "Sex Sells")</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/GzQmvgzjTso"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "Concrete")</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/oyHBaN4JAMY"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "Golden Hour Somewhere")</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/2T99PCSPGYs"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "Call Me What You Like")</i></p>
                            </div>
                            <div className="video_lovejoy_1">
                                <iframe width="315" height="560"
                                src="https://www.youtube.com/embed/8wjdDKXnB9Q"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                                ></iframe>
                                <p className="caption_lovejoy"><i>(Lovejoy's "The Fall")</i></p>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <p>
                        So the order of songs that they played was as follows:
                        <ul className="list_of_songs">
                            <li>Concequences</li>
                            <li>The Taunt</li>
                            <li>Model Buses</li>
                            <li>Cause For Concern</li>
                            <li>Perfume (No Video)</li>
                            <li>Scum</li>
                            <li>Warsaw</li>
                            <li>Sex Sells</li>
                            <li>Concrete</li>
                            <li>One Day (No Video)</li>
                            <li>Golden Hour Somewhere</li>
                            <li>Call Me What You Like</li>
                            <li>The Fall</li>
                        </ul>
                        <br></br>
                        <br></br>
                        They played every song that I wanted to hear and I was so happy, with the obvious exception of 
                        "It's All Futile! It's All Pointless!", "You'll Understand When You're Older" and "Oh Yeah You
                        Gonna Cry?", they played every song I have ever wanted to hear live. Bonus of hearing "From The
                        Start" by Good Kid as its become one of my favourite songs since hearing it live.
                        <br></br>
                        <br></br>
                        After the concert, I had realised how much time had passed and I had to get back to the train. I
                        soon realised that I had missed the last train back to my home town and had to crash at a hostle.
                        That was also my first time doing that as well, staying in a hostle. It was a very interesting. 
                        During the night, I had someone come up to me while I had already been asleep for many hours, bump
                        me on the shoulder and told me I had the wrong bed. She then proceeded to tell me it was okay and
                        got in the other bed. I was so confused and tired that I didn't even know what to say. Like why 
                        wake me up to tell me that? I was so tired and I just wanted to sleep.
                        <br></br>
                        <br></br>
                        With that wild note, I went to sleep and woke up the next day to get the train back home. I spent 
                        the next day just going through the videos and photos that I had taken and just enjoying the memories
                        and experiences that I had. I was one of the best days of my life and I look forward to the next time.
                        <br></br>
                        <br></br>
                        Allons'y,
                        <br></br>
                        <br></br>
                        <b>Thomas Williams</b>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LovejoyConcert