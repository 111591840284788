import {React} from "react"
import Header from '../../Components/Header/header'
import './projects.css'
import Weather from "../../Components/Weather App/weather"
import Quiz from "../../Components/quiz/destiny-2-quiz"

const Projects = () => {
    return (
        <>
             <head>
                <title>Projects</title>
                <meta charset="UTF-8"/>
                <meta name="description" content="Projects page for Thomas Lee Williams
                personal website"/>
                <meta name="keywords" content="HTML, CSS, JavaScript, React"/>
                <meta name="author" content="Thomas Lee Williams"/>
            </head>
            <div className="main_projects">
            <Header/>
                <div className="page_projects">
                    <h1 className="name_projects">Projects</h1>
                    <div className="display-TRDB-projects">
                        
                        <div className="display-TRDB-projects-text">
                            <h2>Thomas Rated Database (TRDB)</h2>
                            <p><i>(Started October 2023, Finished TBC)</i></p>
                            <p><i>(Work In Progress)</i></p>
                            <br></br>
                            <br></br>
                            <p>
                            The Thomas Rated Database (TRDB) is a project I am currently working on to satisfy many different 
                            asspects of my life. Me and my friends are always having discussions about what Movies, TV shows,
                            Video Games and Books are good, and what are not. I am also a person who likes to keep track of 
                            what I have watched, played, and read, as well as what I thought of them.
                            <br></br>
                            <br></br>
                            <div className="display-TRDB-projects-grid">
                                <div>
                                    <img src={require('../../Assets/TRDB-Screenshot.png')} className="TRDB-Image" alt="TRDB-Image"/>
                                    <p className="Image-Desc"><i>Home Screen</i></p>
                                </div>
                                <div>
                                    <img src={require('../../Assets/TRDB-Screenshot-10.png')} className="TRDB-Image" alt="TRDB-Image"/>
                                    <p className="Image-Desc"><i>Login Screen</i></p>
                                </div>
                            </div>
                            <br></br>
                            Now of course you have websites that allow you to do this, but most of them lack either catagories 
                            when displaying and ranking them or don't include all the options I wish for a site to have. Websites 
                            like IMDB and Rotten Tomatoes are great for movies and 
                            TV shows, IMDB even includes video games, but it is not the main focus. Metacritic has this as well
                            but lacks some of the features or funtionality or even some of the media I would want to rate. So one 
                            day I was watching a "Create a clone of IMDB" video and it got me thinking, why not make my own?
                            <br></br>
                            <br></br>
                            <div className="display-TRDB-projects-grid">
                                <div>
                                    <img src={require('../../Assets/TRDB-Screenshot-2.png')} className="TRDB-Image" alt="TRDB-Image"/>
                                    <p className="Image-Desc"><i>Films Main Page</i></p>
                                </div>
                                <div>
                                    <img src={require('../../Assets/TRDB-Screenshot-3.png')} className="TRDB-Image" alt="TRDB-Image"/>
                                    <p className="Image-Desc"><i>TV Shows Main Page</i></p>
                                </div>
                            </div>
                            <br></br>
                            Now I am not going to make a clone of IMDB, as I do not like the design of that site and I wish to try 
                            and do this without as much help and guides as possible. I wish to learn web design and get a stronger
                            understanding of how to use React, and how to use it to make a website that is not only functional, but
                            useful to me and others as well.
                            <br></br>
                            <br></br>
                            <div className="display-TRDB-projects-grid">
                                <div>
                                    <img src={require('../../Assets/TRDB-Screenshot-4.png')} className="TRDB-Image" alt="TRDB-Image"/>
                                    <p className="Image-Desc"><i>Video Games Main Page</i></p>
                                </div>
                                <div>
                                    <img src={require('../../Assets/TRDB-Screenshot-5.png')} className="TRDB-Image" alt="TRDB-Image"/>
                                    <p className="Image-Desc"><i>Individual Film Page</i></p>
                                </div></div>
                            <br></br>
                            Thus the TRDB was born. I am using Next.js 14 for the front end/backend of my project. I have found a liking
                            to how next.js works and how it handles many forms of rendering and its handling of data fetching. I considered
                            svelte for a while when I was first starting out, but I found that I was more comfortable with React and 
                            how it handles state and props. Also with the bigger ecosystem, next/react was the better choice for me. 
                            <br></br>
                            <br></br>
                            <div className="display-TRDB-projects-grid">
                                <div>
                                    <img src={require('../../Assets/TRDB-Screenshot-6.png')} className="TRDB-Image" alt="TRDB-Image"/>
                                    <p className="Image-Desc"><i>Individual Show Page</i></p>
                                </div>
                                <div>
                                    <img src={require('../../Assets/TRDB-Screenshot-7.png')} className="TRDB-Image" alt="TRDB-Image"/>
                                    <p className="Image-Desc"><i>Individual Season Page</i></p>
                                </div>
                            </div>
                            <br></br>
                            The Backend is being handled by a Planetscale database, which is a MySQL database that is serverless. I needed 
                            a fast and dependable database that was easy to set up and use. I had also watched some videos and guides on
                            service and felt comfortable with the idea of using it. The Planetscale database is also free for the first 10
                            million queries, which is more than enough for me. Planetscale will allow me to store all the user data and rating
                            data I will require for the site to function.
                            <br></br>
                            <br></br>
                            <div className="display-TRDB-projects-grid">
                                <div>
                                    <img src={require('../../Assets/TRDB-Screenshot-8.png')} className="TRDB-Image" alt="TRDB-Image"/>
                                    <p className="Image-Desc"><i>Individual Episode Page</i></p>
                                </div>
                                <div>
                                    <img src={require('../../Assets/TRDB-Screenshot-9.png')} className="TRDB-Image" alt="TRDB-Image"/>
                                    <p className="Image-Desc"><i>Individual Video Game Page</i></p>
                                </div>
                            </div>
                            <br></br>
                            I currently have most of the UI and logic for the front end done, but I am still working on the backend
                            and the database to store the user data and the server data. I have yet to find something that will handle
                            some new plans I have for profiles and displaying users rated media. I want to make something that I do
                            not have to redesign in the future, so I am taking my time to get it right.
                            </p>
                        </div>

                    </div>
                    <div className="display-noodleworld-projects">
                        <div className="double-image">
                            <a href="https://noodleworld.co.uk" target="_blank" rel="noreferrer">
                                <img src={require('../../Assets/noodleworld-screenshot-2.png')} className="noodleworld-image"
                                alt="thumbnail-for-post"/>
                            </a>
                            <a href="https://noodleworld.co.uk" target="_blank" rel="noreferrer">
                                <img src={require('../../Assets/noodleworld-screenshot.png')} className="noodleworld-image"
                                alt="thumbnail-for-post"/>
                            </a>
                        </div>
                        <div className="display-noodleworld-projects-text">
                            <h2>Noodleworld Minecraft Server</h2>
                            <p><i>(Started August 2023, Finished October 2023)</i></p>
                            <br></br>
                            <p>
                            The NoodleWorld website came from a need of my friend group to be able to play Minecraft
                            Survival Multiplayer both affordably, and on demand. I, as the group "tech expert", took 
                            on this task to be able to run a minecraft server on demand, using the cloud, and some form 
                            of interface for my friends to be able to interact with. 
                            <br></br>
                            <br></br>
                            As I do not know how to create desktop applications, and do not want my friends to have to 
                            install an application, I opted to build a website for them to interact with, that could be 
                            used either on mobile, or on desktop. This site would also limit the people who could interact
                            with the main dashboard (shown to your left) to users within the private discord server so no 
                            one is able to just interact with it.
                            <br></br>
                            <br></br>
                            For serving the minecraft server, I resorted to using a cheap VM instance with GCP (Google 
                            Cloud Platform) that would be turned on via API calls from an Flask API I created. The website logins using credentials (server side) and obtains a web token to 
                            be authorized to make requests to shut down or boot up the VM.
                            <br></br>
                            <br></br>
                            To actually run the minecraft server itself, I turned to docker compose and an image provided by &#8205;
                            <a href="https://hub.docker.com/r/itzg/minecraft-server">itzg/minecraft-server</a>.
                            Docker would allow for me to shutdown the VM while also allowing for the docker container running
                            the minecraft server to save and shutdown properly. This ensures that my friends do not lose 
                            their progress and docker allows for extreamly speedy start ups.
                            <br></br>
                            <br></br>
                            The entire process took me about 2 weeks to figure out and get right, even now I am constantly 
                            thinking of ways to optimise and configure in order to get the best expereince possible. All I 
                            know is that my group is happy to have a way to play our Minecraft world of 2 years whenever they want,
                            and I am happy that it is costing as little as possible.
                            </p>
                        </div>
                    </div>
                    <div className="display-right-projects">
                        <Quiz />
                        <div className="display-right-projects-text">
                            <h2>
                            Destiny 2 Quiz
                            </h2>
                            <p><i>(Started June 2022, Finished June 2022)</i></p>
                            <br></br>
                            <p>
                            This componant is a Dynamic Destiny quiz about the game and it's lore. At
                            the end of the quiz, a score is displayed of showing how many questions you
                            got right. (I will add what questions were answered wrong later)
                            <br></br>
                            <br></br>
                            A question will be displayed from an array of questions and answers. The answers
                            are mapped (iterated out) as buttons so that you are able to answer the questions
                            by clicking the answer.
                            <br></br>
                            <br></br>
                            If you answer correctly, the buttons will light up green. If the answer is incorrect,
                            the buttons will light up red. This is a fairly simple implimentation of a quiz
                            componant and will be imporved on with time.     
                            </p>
                        </div>
                    </div>
                    <div className="display-left-projects">
                        <Weather/>
                        <div className="display-left-projects-text">
                            <h2>
                            Weather Forcast App
                            </h2>
                            <p><i>(Started April 2022, Finished May 2022)</i></p>
                            <br></br>
                            <p>
                            This component is a weather
                            app built to not only get the current tempreture of a given
                            location, but also the next 4 days forecast weather for the 
                            given location.
                            <br></br>
                            <br></br>
                            The component does this by calling the OpenWeather current
                            weather API to get the current weather for the city, county,
                            state (US only), town, and country entered into the search bar.
                            The exact same happens with the 5 day / 3 hour forecast API.
                            <br></br>
                            <br></br>
                            It then displays the current forecast with the next 4 days 
                            forecast in a easy to see format. The Weather app will display
                            information for the current location, current tempreture, a 
                            description of the weather conditions, the humidity, and the 
                            current wind speed.
                            </p>
                        </div>
                    </div>
                    <div>
                        <p>
                            Noodleworld App
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projects