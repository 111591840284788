import React, {useState, useEffect} from "react";
import './about.css'
import Header from '../../Components/Header/header';
import '../../Assets/profile.jpg'

export default function About() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {

        const changeWidth = () => {
          setScreenWidth(window.innerWidth);
        }
    
        window.addEventListener('resize', changeWidth)
    
        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    
      }, [])


    if (screenWidth > 1270) {
        return (
            <>
                <head>
                    <title>About</title>
                    <meta charset="UTF-8"/>
                    <meta name="description" content="About page for Thomas Lee Williams
                    personal website"/>
                    <meta name="keywords" content="HTML, CSS, JavaScript, React"/>
                    <meta name="author" content="Thomas Lee Williams"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/> 
                </head>
                <div className="main_about">
                    <Header/>
                    <div className="page_about">
                        <h1 className="name_about">About</h1>
                        <div className="profile">
                            <p className="profile-para">Hello, my name is Thomas Lee Williams. I am
                            a Computer Systems and Network (BEng) student at Napier University, and I will 
                            be graduating in June/July of 2023. 
                            <br></br><br></br>
                            I have been taught and worked on various different technologies and softwares within the 
                            networking ecosystem. These include: Cisco routers and switches, Network Simulation Software (Omnet++), 
                            Scripting with python, windows servers, and many different linux features 
                            (SELinux, firewalls, DNS, and web servers).
                            <br></br><br></br>
                            In my free time outside of university, I have been working with docker and kubernetes in an attempt to
                            self-host services like websites, game servers running on Pterodactyl for friends, file servers, and 
                            any other tools that make my work and time more valueable.
                            </p>
                            <img src='https://i.imgur.com/SaD2dHu.jpg' alt="Profile"
                            className="profile-image"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    
    if (screenWidth < 1270) {
        return (
            <>
                <head>
                    <title>About</title>
                    <meta charset="UTF-8"/>
                    <meta name="description" content="About page for Thomas Lee Williams
                    personal website"/>
                    <meta name="keywords" content="HTML, CSS, JavaScript, React"/>
                    <meta name="author" content="Thomas Lee Williams"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/> 
                </head>
                <div className="main_about">
                    <Header/>
                    <div className="page_about">
                        <h1 className="name_about">About</h1>
                        <div className="profile">
                            <p className="profile-para">Hello, my name is Thomas Lee Williams. I am
                            a Computer Systems and Network (BEng) student at Napier University, and I will 
                            be graduating in June/July of 2023. 
                            <br></br><br></br></p>
                            <img src='https://i.imgur.com/SaD2dHu.jpg' alt="Profile"
                            className="profile-image"/>
                            <p className="profile-para">
                            I have been taught and worked on various different technologies and softwares within the 
                            networking ecosystem. These include: Cisco routers and switches, Network Simulation Software (Omnet++), 
                            Scripting with python, windows servers, and many different linux features 
                            (SELinux, firewalls, DNS, and web servers).
                            <br></br><br></br>
                            In my free time outside of university, I have been working with docker and kubernetes in an attempt to
                            self-host services like websites, game servers running on Pterodactyl for friends, file servers, and 
                            any other tools that make my work and time more valueable.
                            </p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
