import React from "react";
import './index.css'
import Header from '../../../../Components/Header/header';
import { HiArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";


const Friendsgiving = () => {
    return (
        <>
            <head>
                <title>Friendsgiving - 2022</title>
                <meta charset="UTF-8"/>
                <meta name="description" content="Blogs page for Thomas Lee Williams
                personal website"/>
                <meta name="keywords" content="HTML, CSS, JavaScript, React"/>
                <meta name="author" content="Thomas Lee Williams"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </head>
            <div className="main_friendsgiving">
                <Header/>
                <div className="page_friendsgiving">
                    <div className="title_stuff">
                        <Link to="/blogs" className='back_arrow-box'>
                            <HiArrowLeft className="back_arrow"/>
                        </Link>
                        <h1 className="name_day_out">TBC</h1>
                    </div>
                    <h2 className="date_friendsgiving"><u>25th November 2022</u></h2>
                    <div className="desc_friendsgiving">
                        <p>
                        TBC
                        </p>
                    </div>
                    <div className="para_friendsgiving">
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Friendsgiving