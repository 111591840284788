import {React} from "react"
import Header from '../../Components/Header/header'
import './resume.css'
import detectElementOverflow from 'detect-element-overflow'
import App from "../../App"

const Resume = () => {
    
    const collisions = detectElementOverflow(Resume, App);
    console.log(collisions)
    
    return (
        <>
             <head>
                <title>Resume</title>
                <meta charset="UTF-8"/>
                <meta name="description" content="Resume page for Thomas Lee Williams
                personal website"/>
                <meta name="keywords" content="HTML, CSS, JavaScript, React"/>
                <meta name="author" content="Thomas Lee Williams"/>
            </head>
            <div className="main_resume">
                <Header/>
                <div className="page_resume">
                    <h1 className="name_resume">Resume</h1>
                    <div className="div_button_resume">
                    <a href="https://drive.google.com/uc?export=download&id=1zrGOe-5YiWu60f7Mk7oDULrsNV03koqb"
                    download>
                        <button className="download_resume"
                        href="/src/assets" download="Thomas Lee Williams Resume.docx">
                            Download Resume
                        </button>
                        </a>
                    </div>
                    <div className="resume-main-body">
                        <h2 className="resume_title">
                                Education
                        </h2>
                        <ul>
                            <li className="resume_list_item">
                                <b>Computer Systems And Networking (BEng) - Edinburgh Napier University</b>
                                <span>
                                    <i className="dates">(2021 - 2023)</i>
                                </span>
                            </li>
                            <ul>
                                <li className="resume_sub_item">
                                    <span>
                                    <b>Grade: </b><i>Second Class Honours (1st Division) : "2:1"</i>
                                    </span>
                                </li>
                                <li className="resume_sub_item">
                                    <span>
                                    <b>Modules: </b><i> Networked Services, Cloud and Advanced Networks, Group Project, 
                                    Practical Networks 3, Computing in Contemporary Society, Security Systems for IoT
                                    Security Testing and Advanced Network Forensics, Digital Forensics,
                                    Scripting for Cybersecurity and Networks, and Network Communications and Analysis
                                    </i>
                                    </span>
                                </li>
                            </ul>
                            <li className="resume_list_item">
                                <b>Computing Networking HND (Higher National Diploma) - Edinburgh College</b>
                                <i className="dates">(2020 - 2021)</i>
                            </li>
                            <ul>
                                <li className="resume_sub_item">
                                    <span>
                                    <b>Grade: </b><i>HND Graded Unit A</i>
                                    </span>
                                </li>
                                <li className="resume_sub_item">
                                    <span>
                                    <b>Modules: </b><i> Private Cloud Virtualisation, Server Administration, 
                                        and Computer Hardware and Software Installation Fundamentals </i>
                                    </span>
                                </li>
                            </ul>
                            <li className="resume_list_item">
                                <b>Computer HNC (Higher National Certificate) - Edinburgh College</b>
                                <i className="dates">(2019 - 2020)</i>
                            </li>
                            <ul>
                                <li className="resume_sub_item">
                                    <span>
                                    <b>Grade: </b><i>HNC Graded Unit B</i>
                                    </span>
                                </li>
                                <li className="resume_sub_item">
                                    <span>
                                    <b>Modules: </b><i> Computer Networking Technologies, Routing and Switching, 
                                    and Basic Software Development </i>
                                    </span>
                                </li>
                            </ul>
                        </ul>
                        <h2 className="resume_title">
                            Experenice
                        </h2>
                        <ul>
                            <li className="resume_list_item">
                                <span>
                                    <b>Computershare - Service Desk Anaylst</b>
                                </span>
                                <span>
                                    <i className="dates">(2023 - Current)</i>
                                </span>
                                <ul>
                                    <li className="resume_sub_item">
                                        <i>1st/2nd Line Support for 16000 employees globally, Support with Windows Active 
                                            Directory, Office 365, and ServiceNow, Incident Management, Problem Management,
                                            Change Management, and Service Request Management, Exchange Online,  Online Chat 
                                            Service experience, Phone Call Service experience, Troubleshooting Skills and much more
                                        </i>
                                    </li>
                                </ul>
                            </li>
                            <li className="resume_list_item">
                                <span>
                                    <b>McDonalds - Crew Member</b>
                                </span>
                                <span>
                                    <i className="dates">(2017 - 2018)</i>
                                </span>
                                <ul>
                                    <li className="resume_sub_item">
                                        <i>Health and Safety awareness, Situation Awareness, Conflict Resolution, 
                                        Customer Service experience, Confidence Building, Patience, Cash Handling,
                                        Teamwork, Team Building, Register Training, Individual Work, Hygiene Awareness</i>
                                    </li>
                                </ul>
                            </li>
                            <li className="resume_list_item">
                                <span>
                                    <b>4 Seasons Health Care - Catering Assistant</b>
                                </span>
                                <span>
                                    <i className="dates">(2017 - 2017)</i>
                                </span>
                                <ul>
                                    <li className="resume_sub_item">
                                        <i>Working with the elderly, Food Preparation, Porter Duties, General Tiding Duties,
                                        Health and Safety, Time Keeping and Management</i>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <h2 className="resume_title">
                            Skills
                        </h2>
                        <ul>
                            <li className="resume_list_item">
                                <b>Languages: </b> <i>Javascript/Typescript, Python, 
                                HTML, CSS, Bash, Tailwind (Cheating a bit here, but it's a language of sorts!), 
                                Shell
                                </i>
                            </li>
                            <li className="resume_list_item">
                                <b>Technologies: </b><i>Linux, Node.js, Docker, Cisco, Git, SELinux, OMNET++, 
                                Azure Active Directory, Windows 2019 Active Directory, ServiceNow, Genesys Cloud
                                </i>
                            </li>
                        </ul>
                        <h2 className="resume_title">
                            Certifications and badges
                        </h2>
                        <div className="certs-grid">
                            <div className="certs">
                                <i>CCNA: Switching, Routing, and Wireless Essentials</i>
                                <a href="https://www.credly.com/badges/947254b4-29b6-4814-b6e6-dc46624c6c01" rel="noreferrer">
                                    <img src={require('../../Assets/CCNASRWE__1_.png')} className="badge"
                                    alt="thumbnail-for-post"/>
                                </a>
                            </div>
                            <div className="certs">
                                <i>CCNA: Enterprise Networking, Security, and Automation</i>
                                <a href="https://www.credly.com/badges/d5343971-73f4-472b-85fe-1c1255c7c125" rel="noreferrer">
                                    <img src={require('../../Assets/CCNAENSA__1_.png')} className="badge"
                                    alt="thumbnail-for-post"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Resume